import { useContext, useState } from "react";
import Section from "./navbar/Section.jsx";
import { ArrowsLeftRight, X, ThumbsUp, UserCheck, Storefront, Coins, Bank, PiggyBank, IdentificationCard, Handshake, Faders, Lightning } from "@phosphor-icons/react";
import SidebarContext from "../context/SidebarContext";
import HoverContext from '../context/HoverContext';

const Sidebar = ({ step, setStep }) => {
    const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext);
    const { isMouseHover, setIsMouseHover } = useContext(HoverContext);

    const mainSection = {
        name: 'BUSINESS FUNDING PORTAL',
        items: [
            {
                icon: ThumbsUp,
                name: 'Simple Business Funding',
                url: '/',
                status: true,
                compo: 'menu--1',
                step: 0,
            },
            {
                icon: ThumbsUp,
                name: 'Get Started',
                url: '/',
                active: false,
                status: false,
                compo: 'menu-0',
                step: 1,
            },
            // {
            //     icon: UserCheck,
            //     name: 'Your Details',
            //     url: '/',
            //     active: false,
            //     status: true,
            //     compo: 'menu-2'
            // },
            {
                icon: Storefront,
                name: 'Business Details',
                url: '/',
                active: false,
                status: false,
                compo: 'menu-1',
                step: 1,
            },
            {
                icon: Coins,
                name: 'Funding Request',
                url: '/',
                active: false,
                status: false,
                compo: 'menu-2',
                step: 1,
            },
            // {
            //     icon: Bank,
            //     name: 'Bank Details Check',
            //     url: '/',
            //     active: false,
            //     status: false,
            //     compo: 'menu-3',
            //     step: 1,
            // },
            {
                icon: PiggyBank,
                name: 'Our Funding Offer',
                url: '/',
                active: false,
                status: false,
                compo: 'menu-4',
                step: 2,
            },
            {
                icon: IdentificationCard,
                name: 'Identity Checks',
                url: '/',
                active: false,
                status: false,
                compo: 'menu-5',
                step: 2,
            },
            {
                icon: Handshake,
                name: 'Contracts',
                url: '/',
                active: false,
                status: false,
                compo: 'menu-6',
                step: 3,
            },
            {
                icon: Faders,
                name: 'Repayment Setup',
                url: '/',
                active: false,
                status: false,
                compo: 'menu-7',
                step: 3,
            },
            {
                icon: Lightning,
                name: 'Funding Sent',
                url: '/',
                active: false,
                status: false,
                compo: 'menu-8',
                step: 3,
            },
        ]
    }

    const mouseEnter = () => {
        setIsMouseHover(true)
    }

    const mouseLeave = () => {
        setIsMouseHover(false)
    }

    const clickSidebarOpen = () => {
        if (isSidebarOpen) setIsMouseHover(false);
        setIsSidebarOpen(!isSidebarOpen)
    }

    const clickX = () => {
        setIsMouseHover(false)
        setIsSidebarOpen(false)
    }

    return (
        <div className={'bg-nav border-t border-[#1a1a62] max-h-full overflow-hidden overflow-y-auto top-0 z-[99] md:block transition-all duration-300 md:transition-none ' + (isSidebarOpen || isMouseHover ? 'w-[18.75rem] ' : 'w-[76px] -left-[18.75rem] ') + ('md:static fixed h-screen')} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
            <div className="flex justify-between items-center px-4 py-4 font-bold">
                {
                    (isMouseHover || isSidebarOpen) && (
                        <h5 className="text-white text-xl font-medium">New Merchant Application</h5>
                    )
                }
                <div className="rounded-full bg-nav-active p-2 hover:cursor-pointer hover:bg-[#5d5da3]">
                    <ArrowsLeftRight className="md:block hidden" color='white' weight="bold" size={20} onClick={clickSidebarOpen} />
                    <X className="md:hidden block" color='white' weight="bold" size={20} onClick={clickX} />
                </div>
            </div>

            <Section
                step={step}
                setStep={setStep}
                name={mainSection.name}
                items={mainSection.items}
                expanded={(isSidebarOpen === true || isMouseHover === true)}
            />
            
        </div>
    )
}

export default Sidebar;