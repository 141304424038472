import { CaretDown } from "@phosphor-icons/react"
import Collapse from "@kunukn/react-collapse";
import { useState } from "react";

import ProcessImg from '../../../assets/img/processing.png'
import TextInput from "../TextInput";
import SelectInput from "../SelectInput";
import { countryOptions, relationshipOptions } from "./config";
import PhonenumberInput from "../PhonenumberInput";
import Text from "../Text";
import CustomSelect from "../CustomSelect";

const BusinessCom = ({ isCollapsed, setIsCollapsed, clickArrow }) => {
  const id = 0, badge = 1;
  const title = `Let's Grow Your Business`
  const subText = `We're excited to support your Merchant’s
growth. Our solutions are tailored to their
needs, and this journey will help them
unlock the financial opportunities their
business deserves.
`

  const [formData, setFormData] = useState({
    mobileNumber: '',
    applicants: '',
    raddress: '',
    birthday: '',
    address: '',
    residence: '',
    postcode: '',
    grant1: '',
    grant2: ''
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.grant1 = formData.grant1 ? '' : 'Gran1 is required.';
    tempErrors.grant2 = formData.grant2 ? '' : 'Grant2 is required.';

    tempErrors.applicants = formData.applicants ? '' : 'Eligible Company Applicants is required.';

    tempErrors.birthday = formData.birthday ? '' : 'Birthday date is required.';
    tempErrors.raddress = formData.raddress ? '' : 'Different Residential Address option is required.';

    tempErrors.mobileNumber = formData.mobileNumber ? '' : 'Phone number is required.';

    if (formData.raddress === 'Yes') {
      tempErrors.residence = formData.residence ? '' : 'Country residence is required.';
      tempErrors.address = formData.address ? '' : 'Your address is required.';
      tempErrors.postcode = formData.postcode ? '' : 'Postcode is required.';
    }

    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      // Submit form logic here
      setIsCollapsed(null);
      const comId = `menu-${id + 1}`
      const element = document.getElementById(comId)
      if (!element) return
      element.scrollIntoView({ behavior: 'smooth', block: "end" });
      console.log('Form is valid, submitting...');
    } else {
      console.log('Form is invalid, not submitting.');
    }
  };

  return (
    <div id={`menu-${id}`} className="grid cols-span-12 rounded-md border shadow-sm bg-white w-full" style={{ overflow: "auto" }}>
      {(isCollapsed !== id) &&
        <div className="md:pl-12 p-6 md:flex gap-12 text-black">
          <div className="justify-end flex md:hidden -mb-4">
            <div className={"transition-all duration-300 hover:cursor-pointer " + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
              <CaretDown size={20} />
            </div>
          </div>
          <div className="flex justify-center md:mb-0 mb-6">
            <img src={ProcessImg} className={`w-40 h-40 aspect-square rounded-full`} alt="" />
          </div>
          <div className="md:w-[calc(100%-208px)] text-center md:text-left">
            <div className="md:flex justify-between gap-6 items-center">
              <h2 className="text-[1.7rem] font-[500]">{title}</h2>
              <div className="flex justify-center gap-6 items-center h-20">
                <div className={`rounded-full flex font-bold text-xs items-center justify-center w-fit gap-3 px-4 py-1 h-fit ${badge === 1 ? 'bg-[#dcfce7]' : 'bg-[#dbeafe]'}`}>
                  <div className={`w-[4px] h-[4px] rounded-full ${badge === 1 ? 'bg-[#458046]' : 'bg-[#6085e2]'}`}></div>
                  <p className={`${badge === 1 ? 'text-[#458046]' : 'text-[#6085e2]'}`}>{badge === 1 ? 'Completed' : 'Pending'}</p>
                </div>
                <div className={"transition-all duration-300 hover:cursor-pointer md:block hidden " + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
                  <CaretDown size={20} />
                </div>
              </div>
            </div>
            <p className="">
              {subText}
            </p>
            <div className="text-center md:text-right mt-8 mb-4">
              <button onClick={() => setIsCollapsed(id)} className="bg-[#000050] text-white px-8 py-2">
                Get Started
              </button>
            </div>
          </div>
        </div>
      }
      <Collapse
        style={{ overflow: "auto" }}
        isOpen={isCollapsed === id}
        className="transition-[height] duration-500 ease-in-out gap-1 items-center xl:justify-between text-[#2E3A48]"
      >
        <div className="p-4 md:flex gap-12 text-black">
          <div className="justify-end flex md:hidden -mb-4">
            <div className={"transition-all duration-300 hover:cursor-pointer" + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
              <CaretDown size={20} />
            </div>
          </div>
          <div className="flex justify-center md:mb-0 mb-6">
            <img src={ProcessImg} className={`w-48 h-48 aspect-square rounded-full`} alt="" />
          </div>
          <div className="md:w-[calc(100%-240px)] text-center md:text-left">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="md:flex justify-between gap-6 items-center">
                <h2 className="text-[1.6rem] font-[500]">{title}</h2>
                <div className="flex justify-center gap-6 items-center h-20">
                  <div className={`rounded-full flex font-bold text-xs items-center justify-center w-fit gap-3 px-4 py-1 h-fit ${badge === 1 ? 'bg-[#dcfce7]' : 'bg-[#dbeafe]'}`}>
                    <div className={`w-[4px] h-[4px] rounded-full ${badge === 1 ? 'bg-[#458046]' : 'bg-[#6085e2]'}`}></div>
                    <p className={`${badge === 1 ? 'text-[#458046]' : 'text-[#6085e2]'}`}>{badge === 1 ? 'Completed' : 'Pending'}</p>
                  </div>
                  <div className={"transition-all duration-300 hover:cursor-pointer hidden md:block " + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
                    <CaretDown size={20} />
                  </div>
                </div>
              </div>
              <p className="">
                {subText}
              </p>
              <div className="mt-8">
                <div className="gap-8 md:flex mt-4">
                  <div className="w-full">
                    <CustomSelect
                      label='Eligible Company Applicants'
                      placeholder={"Select Your Name From The List"}
                      value={formData.applicants}
                      error={errors.applicants}
                      onChange={(value) => { setFormData({ ...formData, applicants: value }) }}
                      options={[{
                        value: ['Mai Quynh Le', 'Senna Building, Gorsuch Place, London, England, E2 8JF'],
                        view: `<div class="font-semibold">Mai Quynh Le</div>
                              <div>Senna Building, Gorsuch Place, London, England, E2 8JF</div>`},
                      {
                        value: ['Alexis Van Lennep', 'Senna Building, Gorsuch Place, London, England, E2 8JF'],
                        view: `<div class="font-semibold">Alexis Van Lennep</div>
                                      <div>Senna Building, Gorsuch Place, London, England, E2 8JF</div>`},
                      {
                        value: ['Michal Marcin Kot', 'Senna Building, Gorsuch Place, London, England, E2 8JF'],
                        view: `<div class="font-semibold">Michal Marcin Kot</div>
                                              <div>Senna Building, Gorsuch Place, London, England, E2 8JF</div>`},
                      ]} />
                  </div>
                </div>

                {formData.applicants &&
                  <div className="gap-8 md:flex mt-4">
                    <div className="md:w-1/2 w-full">
                      <Text
                        label='Director Name'
                        value={formData?.applicants[0] || ''}
                      />
                    </div>
                    <div className="md:w-1/2 w-full">
                      <Text
                        label='Correspondence Address'
                        value={formData?.applicants[1] || ''}
                      />
                    </div>
                  </div>}

                <div className="gap-8 md:flex mt-4">
                  <div className="md:w-1/2 w-full">
                    <TextInput
                      type={'date'}
                      label='Date of Birth'
                      value={formData.birthday}
                      onChange={(value) => { setFormData({ ...formData, birthday: value }) }}
                      placeholder={"DD/MM/YYYY"}
                      error={errors.birthday}
                    />
                  </div>
                  <div className="md:w-1/2 w-full">
                    <SelectInput
                      label='Different Residential Address?'
                      value={formData.raddress}
                      onChange={(value) => { setFormData({ ...formData, raddress: value }) }}
                      placeholder={"Select Option"}
                      error={errors.raddress}
                      options={['Yes', 'No']}
                    />
                  </div>
                </div>

                {formData.raddress === 'Yes' &&
                  <>
                    <div className="gap-8 md:flex mt-4">
                      <div className="md:w-1/2 w-full">
                        <SelectInput
                          label='Country of Residence'
                          value={formData.residence}
                          onChange={(value) => { setFormData({ ...formData, residence: value }) }}
                          placeholder={"Select Country of Residence"}
                          error={errors.residence}
                          options={countryOptions}
                        />
                      </div>
                      <div className="md:w-1/2 w-full">
                        <TextInput
                          label='Address Line 1'
                          value={formData.address}
                          onChange={(value) => { setFormData({ ...formData, address: value }) }}
                          placeholder={"Flat Number / House Name"}
                          error={errors.address}
                        />
                      </div>
                    </div>

                    <div className="gap-8 md:flex mt-4">
                      <div className="md:w-1/2 w-full">
                        <TextInput
                          label='Postcode'
                          value={formData.postcode}
                          onChange={(value) => { setFormData({ ...formData, postcode: value }) }}
                          placeholder={"Postcode"}
                          error={errors.postcode}
                        />
                      </div>
                      <div className="md:w-1/2 w-full"></div>
                    </div>
                  </>}

                <div className="gap-8 md:flex mt-4">
                  <div className="md:w-1/2 w-full">
                    <PhonenumberInput
                      label='Mobile Number'
                      value={formData.mobileNumber}
                      onChange={(value) => { setFormData({ ...formData, mobileNumber: value }) }}
                      placeholder={"00000000000"}
                      error={errors.mobileNumber}
                    />
                  </div>
                  <div className="md:w-1/2 w-full"></div>
                </div>
              </div>
              <div className="relative flex items-start !mt-12 bg-[#f1f4f9] p-4 rounded-lg">
                <div className={`${errors.grant1 && 'border-red-500 border'} h-5 w-5 p-[1px] flex items-center rounded mt-1`}>
                  <input onChange={(e) => setFormData({ ...formData, grant1: e.target.checked })} id="comments" aria-describedby="comments-description" name="comments" type="checkbox" className={`h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600`} />
                </div>
                <div className="ml-3 text-sm leading-6 w-[calc(100%-32px)] text-left">
                  <p>I grant consent to SAPI for the processing of my personal data for
                    business purposes, including sharing personal data of others within my
                    1
                    business, and conducting essential checks as required. See <a
                      href="https://sapi.com/privacy-policy/" target="_blank"
                      rel="noreferrer">SAPI Privacy Policy</a>.</p>
                </div>
              </div>
              <div className="relative flex items-start !mt-8 bg-[#f1f4f9] p-4 rounded-lg">
                <div className={`${errors.grant2 && 'border-red-500 border'} h-5 w-5 p-[1px] flex items-center rounded mt-1`}>
                  <input onChange={(e) => setFormData({ ...formData, grant2: e.target.checked })} id="comments" aria-describedby="comments-description" name="comments" type="checkbox" className={`h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600`} />
                </div>
                <div className="ml-3 text-sm leading-6 w-[calc(100%-32px)] text-left">
                  <p>I grant consent for SAPI to conduct a business credit check as part
                    of our application. I understand that this involves SAPI obtaining
                    information about the credit history of my business, myself, and other
                    individuals within my business from credit reference agencies.</p>
                </div>
              </div>

              <div className="text-center md:text-right !mt-14 !mb-6">
                <button
                  type='submit'
                  className="bg-[#000050] text-white px-8 py-2"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default BusinessCom;