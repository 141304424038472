
const Item = ({ Icon, name, url, active, state, status, expanded, compo }) => {
    // console.log(status1, status)
    const scrollToComponent = () => {
        const element = document.getElementById(compo)
        if (!element) return
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    return (
        <button disabled={state} onClick={() => scrollToComponent(compo)} className={'w-full flex justify-between items-center px-6 py-3 hover:bg-nav-hover gap-2 ' + (active ? 'bg-nav-active' : '')}>
            <div className="flex gap-4">
                <Icon color='white' weight="bold" size={20} />
                {
                    expanded && (
                        <p className='text-white font-bold text-sm'>{name}</p>
                    )
                }
            </div>
            <div className={`rounded-full w-[17px] h-[17px] ${expanded && 'bg-[#3f3f3f]'} flex justify-center items-center py-[5px] px-[5px]`}>
                <div className={`rounded-full w-[7px] h-[7px] ${state ? 'bg-green-500' : 'bg-gray-400'}`} />
            </div>
        </button>
    )
}

export default Item;