import Dashboard from "./layouts/Dashboard.jsx";
import './assets/css/font-awesome/css/font-awesome.min.css'

function App() {
  return (
    <Dashboard />
  )
}

export default App;
