import { CaretDown } from "@phosphor-icons/react"
import Collapse from "@kunukn/react-collapse";

import ContractsImg from '../../../assets/img/contracts.png'

const SignContractCom = ({ isCollapsed, setIsCollapsed, clickArrow }) => {
  const id = 6, badge = 0;
  const title = `Nearly There! Let's Sign Contracts`
  const subText = `You're almost at the finish line. Review the terms, sign the contracts,
  and we'll finalise your funding. Your growth journey with
  {partnerName} & SAPI is just a few clicks away.`

  return (
    <div id={`menu-${id}`} className="grid cols-span-12 rounded-md border shadow-sm bg-white w-full" style={{ overflow: "auto" }}>
      {(isCollapsed !== id) &&
        <div className="md:pl-12 p-6 md:flex gap-12 text-black">
          <div className="justify-end flex md:hidden -mb-4">
            <div className={"transition-all duration-300 hover:cursor-pointer " + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
              <CaretDown size={20} />
            </div>
          </div>
          <div className="flex justify-center md:mb-0 mb-6">
            <img src={ContractsImg} className={`w-40 h-40 aspect-square rounded-full`} alt="" />
          </div>
          <div className="md:w-[calc(100%-208px)] text-center md:text-left">
            <div className="md:flex justify-between gap-6 items-center">
              <h2 className="text-[1.7rem] font-[500]">{title}</h2>
              <div className="flex justify-center gap-6 items-center h-20">
                <div className={`rounded-full flex font-bold text-xs items-center justify-center w-fit gap-3 px-4 py-1 h-fit ${badge === 1 ? 'bg-[#dcfce7]' : 'bg-[#dbeafe]'}`}>
                  <div className={`w-[4px] h-[4px] rounded-full ${badge === 1 ? 'bg-[#458046]' : 'bg-[#6085e2]'}`}></div>
                  <p className={`${badge === 1 ? 'text-[#458046]' : 'text-[#6085e2]'}`}>{badge === 1 ? 'Completed' : 'Pending'}</p>
                </div>
                <div className={"transition-all duration-300 hover:cursor-pointer md:block hidden " + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
                  <CaretDown size={20} />
                </div>
              </div>
            </div>
            <p className="">
              {subText}
            </p>
            <div className="text-center md:text-right mt-8 mb-4">
              <button onClick={() => setIsCollapsed(id)} className="bg-[#000050] text-white px-8 py-2">
                Get Started
              </button>
            </div>
          </div>
        </div>
      }
      <Collapse
        style={{ overflow: "auto" }}
        isOpen={isCollapsed === id}
        className="transition-[height] duration-500 ease-in-out gap-1 items-center xl:justify-between text-[#2E3A48]"
      >
        <div className="p-4 md:flex gap-12 text-black">
          <div className="justify-end flex md:hidden -mb-4">
            <div className={"transition-all duration-300 hover:cursor-pointer" + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
              <CaretDown size={20} />
            </div>
          </div>
          <div className="flex justify-center md:mb-0 mb-6">
            <img src={ContractsImg} className={`w-48 h-48 aspect-square rounded-full`} alt="" />
          </div>
          <div className="md:w-[calc(100%-240px)] text-center md:text-left">
            <div className="md:flex justify-between gap-6 items-center">
              <h2 className="text-[1.6rem] font-[500]">{title}</h2>
              <div className="flex justify-center gap-6 items-center h-20">
                <div className={`rounded-full flex font-bold text-xs items-center justify-center w-fit gap-3 px-4 py-1 h-fit ${badge === 1 ? 'bg-[#dcfce7]' : 'bg-[#dbeafe]'}`}>
                  <div className={`w-[4px] h-[4px] rounded-full ${badge === 1 ? 'bg-[#458046]' : 'bg-[#6085e2]'}`}></div>
                  <p className={`${badge === 1 ? 'text-[#458046]' : 'text-[#6085e2]'}`}>{badge === 1 ? 'Completed' : 'Pending'}</p>
                </div>
                <div className={"transition-all duration-300 hover:cursor-pointer hidden md:block " + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
                  <CaretDown size={20} />
                </div>
              </div>
            </div>
            <p className="">
              {subText}
            </p>
            <p className="mt-8">
              Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus
              elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu,
              consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a,
              tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet.
              Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi.
            </p>
            <p className="mt-4">
              Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem
              quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel,
              luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae
              sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus
              tincidunt. Duis leo. Sed fringilla mauris sit amet nibh.
            </p>
            <p className="mt-4">
              Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit
              cursus nunc, quis gravida magna mi a libero. Fusce vulputate eleifend sapien. Vestibulum
              purus quam, scelerisque ut, mollis sed, nonummy id, metus. Nullam accumsan lorem in dui.
              Cras ultricies mi eu turpis hendrerit fringilla. Vestibulum ante ipsum primis in faucibus orci
              luctus et ultrices posuere cubilia Curae; In ac dui quis mi consectetuer lacinia. Nam pretium
              turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed
              aliquam ultrices mauris. Integer ante arcu, accumsan a, consectetuer eget, posuere ut,
              mauris. Praesent adipiscing. Phasellus ullamcorper ipsum rutrum nunc. Nunc nonummy
              metus. Vestibulum volutpat pretium libero. Cras id dui. Aenean ut
            </p>
            <div className="text-center md:text-right mt-14 mb-6">
              <button className="bg-[#000050] text-white px-8 py-2" onClick={() => {
                setIsCollapsed(null);
                const comId = `menu-${id + 1}`
                const element = document.getElementById(comId)
                if (!element) return
                element.scrollIntoView({ behavior: 'smooth', block: "end" });
              }}>
                Submit
              </button>
            </div>

            
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default SignContractCom;