const TextareaInput = ({ value, onChange, error, type, label, placeholder, isRequired, rows = 3 }) => {
  return (
    <div className="w-full relative mt-8 md:mt-4">
      <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 text-left">{label}</label>
      <textarea
        className={`${error ? 'border-red-500' : 'border-gray-300'} resize-none w-full border bg-gray-50 outline-[#000080] text-gray-900 text-sm rounded-lg focus:ring-[#000050] focus:border-[#000050] block w-full p-2.5`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        required={isRequired}
        rows={rows}
      />
      {error && <p className="text-red-500 text-[13px] mt-1 text-left">{error}</p>}
    </div>
  )
}

export default TextareaInput;