import { useState } from 'react';

import Sidebar from './Sidebar.jsx';
import Board from './Board.jsx';

const Main = () => {
    const [step, setStep] = useState(0)
    const [achiveditems, setAchivedItems] = useState([])

    return (
        <div className='flex grow relative overflow-hidden'>
            <Sidebar step={step} setStep={setStep} achiveditems={achiveditems} />
            <Board step={step} setStep={setStep} achiveditems={achiveditems} setAchivedItems={setAchivedItems} />
        </div>
    )
}

export default Main;