import { phonenumberOptions } from "./card_components/config";

const PhonenumberInput = ({ value, onChange, error, type, label, placeholder, isRequired }) => {
  return (
    <div className="w-full relative mt-8 md:mt-4">
      <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 text-left">{label}</label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center w-[72px]">
          <label className="sr-only">Country</label>
          <select className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-1 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm">
            {phonenumberOptions.map((val, ind)=>(
              <option value={val} key={ind}>{val}</option>
            ))}
          </select>
        </div>
        <input
          className={`${error ? 'border-red-500' : 'border-gray-300'} pl-20 w-full border bg-gray-50 outline-[#000080] text-gray-900 text-sm rounded-lg focus:ring-[#000050] focus:border-[#000050] block w-full p-2.5`}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          type={(type && type !== 'date') ? type : "text"}
          required={isRequired}
          onFocus={(e) => {
            if (type === 'date') e.target.type = 'date'
          }}
        />
      </div>
      {error && <p className="text-red-500 text-[13px] mt-1 text-left">{error}</p>}
    </div>
  )
}

export default PhonenumberInput;