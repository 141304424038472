import { CaretDown } from "@phosphor-icons/react"
import Collapse from "@kunukn/react-collapse";
import { useState } from "react";

import EligibilityImg from '../../../assets/img/eligiblity-check.png'
import TextInput from "../TextInput";
import SelectInput from "../SelectInput";
import { countryOptions, businessTypeOptions } from "./config";
// import PhonenumberInput from "../PhonenumberInput";

const EligibilityCheckCom = ({ isCollapsed, setIsCollapsed, clickArrow, setShowOtherCards, setShowAlert, setStep }) => {
  const id = -1;
  const title = `Eligibility Check`
  const subText = `We understand that every business is
  different. Complete our one minute eligibility
  check to see if we can help your Merchant
  grow their business`

  const [formData, setFormData] = useState({
    businessType: '',
    countryIncorp: 'United Kingdom',
    companyName: '',
    websiteLink: '',
    email: '',
    // turnover: '',
    merchant: '',
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let tempErrors = {};
    // tempErrors.businessOld = formData.businessOld ? '' : 'Phone number is required.';
    // tempErrors.websiteLink = formData.websiteLink ? '' : 'Payment Processor Name Type is required.';
    tempErrors.email = formData.email ? (/\S+@\S+\.\S+/.test(formData.email) ? '' : 'Email is not valid.') : 'Email is required.';
    tempErrors.businessType = formData.businessType ? '' : 'Business Type is required.';
    tempErrors.countryIncorp = formData.countryIncorp ? '' : 'Country of Incorporation Type is required.';
    tempErrors.companyName = formData.companyName ? '' : 'Company Number is required.';
    // tempErrors.turnover = formData.turnover ? '' : 'Previous Turnover is required.';
    tempErrors.merchant = formData.merchant ? '' : 'Payment Processor Name Type is required.';
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setIsCollapsed(null);
      setShowOtherCards(true)
      setShowAlert(true)
      setStep(1)

      setTimeout(() => {
        const toastElement = document.getElementById('toast-check')
        if (toastElement) toastElement.scrollIntoView({ behavior: 'smooth', block: "start" });
        setIsCollapsed(0);
        // const comId = `menu-0`
        // const element = document.getElementById(comId)
        // if (!element) return
        // element.scrollIntoView({ behavior: 'smooth', block: "start" });
      }, 500)

      console.log('Form is valid, submitting...');
    } else {
      console.log('Form is invalid, not submitting.');
    }
  };

  return (
    <div id={`menu-${id}`} className="grid cols-span-12 rounded-md border shadow-sm bg-white w-full" style={{ overflow: "auto" }}>
      {(isCollapsed !== id) &&
        <div className="md:pl-12 p-6 md:flex gap-12 text-black">
          <div className="justify-end flex md:hidden -mb-4">
            <div className={"transition-all duration-300 hover:cursor-pointer " + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
              <CaretDown size={20} />
            </div>
          </div>
          <div className="flex justify-center md:mb-0 mb-6">
            <img src={EligibilityImg} className={`w-40 h-40 aspect-square rounded-full`} alt="" />
          </div>
          <div className="md:w-[calc(100%-208px)] text-center md:text-left">
            <div className="md:flex justify-between gap-6 items-center">
              <h2 className="text-[1.7rem] font-[500]">{title}</h2>
              <div className="flex justify-center gap-6 items-center h-20">
                {/* <div className={`rounded-full flex font-bold text-xs items-center justify-center w-fit gap-3 px-4 py-1 h-fit ${badge === 1 ? 'bg-[#dcfce7]' : 'bg-[#dbeafe]'}`}>
                  <div className={`w-[4px] h-[4px] rounded-full ${badge === 1 ? 'bg-[#458046]' : 'bg-[#6085e2]'}`}></div>
                  <p className={`${badge === 1 ? 'text-[#458046]' : 'text-[#6085e2]'}`}>{badge === 1 ? 'Completed' : 'Pending'}</p>
                </div> */}
                <div className={"transition-all duration-300 hover:cursor-pointer md:block hidden " + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
                  <CaretDown size={20} />
                </div>
              </div>
            </div>
            <p className="">
              {subText}
            </p>
            <div className="text-center md:text-right mt-8 mb-4">
              <button onClick={() => setIsCollapsed(id)} className="bg-[#000050] text-white px-8 py-2">
                Get Started
              </button>
            </div>
          </div>
        </div>
      }
      <Collapse
        style={{ overflow: "auto" }}
        isOpen={isCollapsed === id}
        className="transition-[height] duration-500 ease-in-out gap-1 items-center xl:justify-between text-[#2E3A48]"
      >
        <div className="p-4 md:flex gap-12 text-black">
          <div className="justify-end flex md:hidden -mb-4">
            <div className={"transition-all duration-300 hover:cursor-pointer" + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
              <CaretDown size={20} />
            </div>
          </div>
          <div className="flex justify-center md:mb-0 mb-6">
            <img src={EligibilityImg} className={`w-48 h-48 aspect-square rounded-full`} alt="" />
          </div>
          <div className="md:w-[calc(100%-240px)] text-center md:text-left">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="md:flex justify-between gap-6 items-center">
                <h2 className="text-[1.6rem] font-[500]">{title}</h2>
                <div className="flex justify-center gap-6 items-center h-20">
                  {/* <div className={`rounded-full flex font-bold text-xs items-center justify-center w-fit gap-3 px-4 py-1 h-fit ${badge === 1 ? 'bg-[#dcfce7]' : 'bg-[#dbeafe]'}`}>
                    <div className={`w-[4px] h-[4px] rounded-full ${badge === 1 ? 'bg-[#458046]' : 'bg-[#6085e2]'}`}></div>
                    <p className={`${badge === 1 ? 'text-[#458046]' : 'text-[#6085e2]'}`}>{badge === 1 ? 'Completed' : 'Pending'}</p>
                  </div> */}
                  <div className={"transition-all duration-300 hover:cursor-pointer hidden md:block " + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
                    <CaretDown size={20} />
                  </div>
                </div>
              </div>
              <p className="">
                {subText}
              </p>
              <div className="mt-8">
                <div className="gap-8 md:flex mt-4">
                  <div className="md:w-1/2 w-full">
                    <SelectInput
                      label='Country of Incorporation'
                      value={formData.countryIncorp}
                      onChange={(value) => { setFormData({ ...formData, countryIncorp: value }) }}
                      placeholder={"Select Country of Incorporation"}
                      error={errors.countryIncorp}
                      options={countryOptions}
                    />
                  </div>
                  <div className="md:w-1/2 w-full">
                    <SelectInput
                      label='Business Type'
                      value={formData.businessType}
                      onChange={(value) => { setFormData({ ...formData, businessType: value }) }}
                      placeholder={"Select Business Type"}
                      error={errors.businessType}
                      options={businessTypeOptions}
                    />
                  </div>
                </div>

                <div className="gap-8 md:flex mt-4">
                  <div className="gap-4 md:flex md:w-1/2 w-full items-between">
                    {/* <div className="md:w-2/3"> */}
                      <TextInput
                        label='Company Name'
                        value={formData.companyName}
                        onChange={(value) => { setFormData({ ...formData, companyName: value }) }}
                        placeholder={"Corporate Legal Entity Name"}
                        error={errors.companyName}
                      />
                    {/* </div> */}
                    {/* <div className=" flex flex-row-reverse md:w-1/3 w-full mt-11 items-right">
                      <button
                        type='button'
                        className="bg-[#000050] text-white px-8 py-2 md:max-w-[120px] w-full"
                      >
                        Search
                      </button>
                    </div> */}
                  </div>
                  <div className="md:w-1/2 w-full">
                    <TextInput
                      label='Business Email'
                      value={formData.email}
                      onChange={(value) => { setFormData({ ...formData, email: value }) }}
                      placeholder={"Email Address"}
                      error={errors.email}
                    />
                  </div>
                </div>

                <div className="gap-8 md:flex mt-4">
                  {/* <div className="md:w-1/2 w-full">
                    <SelectInput
                      label='How Old Is Your Bussiness?'
                      value={formData.businessOld}
                      onChange={(value) => { setFormData({ ...formData, businessOld: value }) }}
                      placeholder={"Select Bussiness Age"}
                      error={errors.businessOld}
                      options={['Less Than 1 Year Old', '1 to 3 Years Old', 'Over 3 Years Old']}
                    />
                  </div> */}
                  <div className="md:w-1/2 w-full">
                    <SelectInput
                      label='Existing Merchant Cash Advance'
                      value={formData.merchant}
                      onChange={(value) => { setFormData({ ...formData, merchant: value }) }}
                      placeholder={"Do You Have An Existing Cash Advance?"}
                      error={errors.merchant}
                      options={['Yes', 'No']}
                    />
                  </div>
                  <div className="md:w-1/2 w-full">
                    <TextInput
                      label='Business Website'
                      value={formData.websiteLink}
                      onChange={(value) => { setFormData({ ...formData, websiteLink: value }) }}
                      placeholder={"Website"}
                      type={"URL"}
                    />
                  </div>
                  {/* <div className="md:w-1/2 w-full">
                    <TextInput
                      label='Previous 12 Months Turnover (£)'
                      value={formData.turnover}
                      onChange={(value) => { setFormData({ ...formData, turnover: value }) }}
                      placeholder={"000,000"}
                      error={errors.turnover}
                      type={'number'}
                    />
                  </div> */}
                </div>
                {/* 
                <div className="gap-8 md:flex mt-4">
                
                  <div className="md:w-1/2 w-full">
                  </div>
                </div> */}
              </div>

              <div className="flex justify-between gap-6 !mt-14 !mb-6 items-center">
                {/* <a href="#" className="text-left text-sm md:text-base"><u>Continue Existing Application</u></a> */}
                <button
                  type='submit'
                  className="bg-[#000050] text-white px-8 py-2"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default EligibilityCheckCom;