import Header from '../components/Header.jsx';
import Main from '../components/Main.jsx';
import SidebarContext from '../context/SidebarContext';
import HoverContext from '../context/HoverContext';
import { useState } from 'react';

const Dashboard = () => {
    const [ isSidebarOpen, setIsSidebarOpen ] = useState(true);
    const [ isMouseHover, setIsMouseHover ] = useState(false);

    return (
        <div className='h-screen flex flex-col'>
            <HoverContext.Provider value={{isMouseHover, setIsMouseHover}}>
                <SidebarContext.Provider value={{isSidebarOpen, setIsSidebarOpen}}>
                    <Header />
                    <Main />
                </SidebarContext.Provider>
            </HoverContext.Provider>
        </div>
    )
}

export default Dashboard;