import { CaretDown } from "@phosphor-icons/react"
import Collapse from "@kunukn/react-collapse";
import { useState } from "react";

import FundingrequestImg from '../../../assets/img/fundingrequest.png'
import SelectInput from "../SelectInput";
import TextInput from "../TextInput";
import { BusinessCategoryOptions, fundingOptions } from "./config";
import TextareaInput from "../TextareaInput";

const MuchMoneyCom = ({ isCollapsed, setIsCollapsed, clickArrow }) => {
  const id = 2, badge = 0;
  const title = `How Much Money Do You Need?`
  const subText = `Let us know the amount of funding they
  require to reach their goals. Whether it's to
  expand, restock, or innovate, our financial
  solutions can help them take that next step.
  `

  const [formData, setFormData] = useState({
    requestedFunding: '',
    fundingReason: '',
    bussinessCategory: '',
    cash: '',
    creditcard: '',
    other: '',
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.requestedFunding = formData.requestedFunding ? '' : 'Funding amount is required.';
    tempErrors.fundingReason = formData.fundingReason ? '' : 'Funding reason is required.';
    // tempErrors.bussinessCategory = formData.bussinessCategory ? '' : 'Business category is required.';
    tempErrors.cash = formData.cash ? '' : 'Income source cash (%) is required.';
    tempErrors.creditcard = formData.creditcard ? '' : 'Income source credit card (%) is required.';
    tempErrors.other = formData.other ? '' : 'Income source other (%) is required.';

    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      console.log('Form is valid, submitting...');
      setIsCollapsed(null);
      const comId = `menu-${id + 1}`
      const element = document.getElementById(comId)
      if (!element) return
      element.scrollIntoView({ behavior: 'smooth', block: "end" });
    } else {
      console.log('Form is invalid, not submitting.');
    }
  };

  return (
    <div id={`menu-${id}`} className="grid cols-span-12 rounded-md border shadow-sm bg-white w-full" style={{ overflow: "auto" }}>
      {(isCollapsed !== id) &&
        <div className="md:pl-12 p-6 md:flex gap-12 text-black">
          <div className="justify-end flex md:hidden -mb-4">
            <div className={"transition-all duration-300 hover:cursor-pointer " + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
              <CaretDown size={20} />
            </div>
          </div>
          <div className="flex justify-center md:mb-0 mb-6">
            <img src={FundingrequestImg} className={`w-40 h-40 aspect-square rounded-full`} alt="" />
          </div>
          <div className="md:w-[calc(100%-208px)] text-center md:text-left">
            <div className="md:flex justify-between gap-6 items-center">
              <h2 className="text-[1.7rem] font-[500]">{title}</h2>
              <div className="flex justify-center gap-6 items-center h-20">
                <div className={`rounded-full flex font-bold text-xs items-center justify-center w-fit gap-3 px-4 py-1 h-fit ${badge === 1 ? 'bg-[#dcfce7]' : 'bg-[#dbeafe]'}`}>
                  <div className={`w-[4px] h-[4px] rounded-full ${badge === 1 ? 'bg-[#458046]' : 'bg-[#6085e2]'}`}></div>
                  <p className={`${badge === 1 ? 'text-[#458046]' : 'text-[#6085e2]'}`}>{badge === 1 ? 'Completed' : 'Pending'}</p>
                </div>
                <div className={"transition-all duration-300 hover:cursor-pointer md:block hidden " + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
                  <CaretDown size={20} />
                </div>
              </div>
            </div>
            <p className="">
              {subText}
            </p>
            <div className="text-center md:text-right mt-8 mb-6 mb-4">
              <button onClick={() => setIsCollapsed(id)} className="bg-[#000050] text-white px-8 py-2">
                Get Started
              </button>
            </div>
          </div>
        </div>
      }
      <Collapse
        style={{ overflow: "auto" }}
        isOpen={isCollapsed === id}
        className="transition-[height] duration-500 ease-in-out gap-1 items-center xl:justify-between text-[#2E3A48]"
      >
        <div className="p-4 md:flex gap-12 text-black">
          <div className="justify-end flex md:hidden -mb-4">
            <div className={"transition-all duration-300 hover:cursor-pointer" + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
              <CaretDown size={20} />
            </div>
          </div>
          <div className="flex justify-center md:mb-0 mb-6">
            <img src={FundingrequestImg} className={`w-48 h-48 aspect-square rounded-full`} alt="" />
          </div>
          <div className="md:w-[calc(100%-240px)] text-center md:text-left">
            <form onSubmit={handleSubmit} className="space-y-4">

              <div className="md:flex justify-between gap-6 items-center">
                <h2 className="text-[1.6rem] font-[500]">{title}</h2>
                <div className="flex justify-center gap-6 items-center h-20">
                  <div className={`rounded-full flex font-bold text-xs items-center justify-center w-fit gap-3 px-4 py-1 h-fit ${badge === 1 ? 'bg-[#dcfce7]' : 'bg-[#dbeafe]'}`}>
                    <div className={`w-[4px] h-[4px] rounded-full ${badge === 1 ? 'bg-[#458046]' : 'bg-[#6085e2]'}`}></div>
                    <p className={`${badge === 1 ? 'text-[#458046]' : 'text-[#6085e2]'}`}>{badge === 1 ? 'Completed' : 'Pending'}</p>
                  </div>
                  <div className={"transition-all duration-300 hover:cursor-pointer hidden md:block " + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
                    <CaretDown size={20} />
                  </div>
                </div>
              </div>
              <p className="">
                {subText}
              </p>
              <div className="mt-8">
                <div className="gap-8 md:flex mt-4">
                  <div className="md:w-1/2 w-full">
                    <TextInput
                      label='Requested Funding Amount (£)'
                      value={formData.requestedFunding}
                      onChange={(value) => { setFormData({ ...formData, requestedFunding: value }) }}
                      placeholder={"000,000"}
                      error={errors.requestedFunding}
                      type={'number'}
                    />
                  </div>
                  <div className="md:w-1/2 w-full">
                    <SelectInput
                      label='Funding Reason'
                      value={formData.fundingReason}
                      onChange={(value) => { setFormData({ ...formData, fundingReason: value }) }}
                      placeholder={"Select Funding Reason"}
                      error={errors.fundingReason}
                      options={fundingOptions}
                    />
                  </div>
                </div>

                {/* <div className="gap-8 md:flex mt-4">
                  <SelectInput
                    label='Business Category'
                    value={formData.bussinessCategory}
                    onChange={(value) => { setFormData({ ...formData, bussinessCategory: value }) }}
                    placeholder={"Select Business Category"}
                    error={errors.bussinessCategory}
                    options={BusinessCategoryOptions}
                  />
                </div>
                <div className="gap-8 md:flex mt-4">
                  <TextareaInput
                    label='What Does Your Business Do'
                    value={formData.businessDo}
                    onChange={(value) => { setFormData({ ...formData, businessDo: value }) }}
                    placeholder={`Minimum of 100 words telling us: what products/services you sell to customers, and how your customers pay for your products & services?`}
                    error={errors.businessDo}
                    rows={10}
                  />
                </div> */}
              </div>
              <div className="gap-8 md:flex mt-4">
                <div className="md:w-1/2 w-full">
                  <TextInput
                    label='Income Source - Cash (%)'
                    value={formData.cash}
                    onChange={(value) => {
                      let val = parseInt(value, 10);
                      let val1 = parseInt(formData.creditcard, 10);
                      let val2 = parseInt(formData.other, 10);

                      if (isNaN(val1)) val1 = 0;
                      if (isNaN(val2)) val2 = 0;

                      if (val < 0) val = 0;
                      if (val > 100) val = 100;
                      if (val > (100 - val1)) val = 100 - val1;

                      let val3 = 100 - val - val1
                      setFormData({ ...formData, cash: val, other: val3 })
                    }}
                    placeholder={"% of Cash Payment Customers"}
                    error={errors.cash}
                    type={'number'}
                  />
                </div>
                <div className="md:w-1/2 w-full">
                  <TextInput
                    label='Income Source - Credit Card (%)'
                    value={formData.creditcard}
                    onChange={(value) => {
                      let val = parseInt(value, 10);
                      let val1 = parseInt(formData.cash, 10);
                      let val2 = parseInt(formData.other, 10);

                      if (isNaN(val1)) val1 = 0;
                      if (isNaN(val2)) val2 = 0;

                      if (val < 0) val = 0;
                      if (val > 100) val = 100;
                      if (val > (100 - val1)) val = 100 - val1;

                      let val3 = 100 - val - val1

                      setFormData({ ...formData, creditcard: val, other: val3 })
                    }}
                    placeholder={"% of Card Payment Customers"}
                    error={errors.creditcard}
                    type={'number'}
                  />
                </div>
              </div>
              <div className="gap-8 md:flex mt-4">
                <div className="md:w-1/2 w-full">
                  <TextInput
                    label='Income Source - Other (%)'
                    value={formData.other}
                    onChange={(value) => {}}
                    placeholder={"Cheques, Bank Transfers…"}
                    error={errors.other}
                    type={'number'}
                  />
                </div>
                <div className="md:w-1/2 w-full">
                </div>
              </div>
              <div className="text-center md:text-right !mt-14 !mb-6">
                <button type="submit" className="bg-[#000050] text-white px-8 py-2">
                  Submit
                </button>
              </div>
            </form>

          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default MuchMoneyCom;