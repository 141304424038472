import { useState } from "react";

import MapImg from "../../assets/img/map.jpg";
import BusinessCom from "../atoms/card_components/BusinessCom";
// import TellUsCom from "../atoms/card_components/TellUsCom";
import TellUsAboutCom from "../atoms/card_components/TellUsAboutCom";
import MuchMoneyCom from "../atoms/card_components/MuchMoneyCom";
import BankAccountCom from "../atoms/card_components/BankAccountCom";
import NewsCom from "../atoms/card_components/NewsCom";
import IdCheckCom from "../atoms/card_components/IdCheckCom";
import SignContractCom from "../atoms/card_components/SignContractCom";
import RepaymentCom from "../atoms/card_components/RepaymentCom";
import MoneyOnWayCom from "../atoms/card_components/MoneyOnWayCom";
import YourFundOnWayCom from "../atoms/card_components/YourFundOnWayCom";
import EligibilityCheckCom from "../atoms/card_components/EligibilityCheckCom";

const Overview = ({ step, setStep, achivedItems, setAchivedItems }) => {
  const [isCollapsed, setIsCollapsed] = useState(-1);
  const [showOtherCards, setShowOtherCards] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showMerchant, setShowMerchant] = useState(false);

  const clickArrow = (id) => {
    const colId = id === isCollapsed ? null : id
    setIsCollapsed(colId);
  }

  const handleImageLoad = (event) => {
    event.target.parentNode.style.opacity = 1;
  };

  return (
    <div className="p-4 bg-[#f1f4f9] grow pb-20 sm:pt-12 pt-8">
      <div className="sm:flex w-full gap-8">
        {/* <div className="sm:w-1/4 mt-4 sm:mt-0 text-[#3c4b5d]">
          <div className="w-full bg-white p-6 rounded-lg mb-6">
            <p className="mt-2 font-[500] tracking-tight text-[#3c4b5d] text-[1.7rem]">Fast, fair and flexible funding to grow your business</p>
            <p className="mt-6 leading-8 text-[#3c4b5d]">
              Get access to up to £100,000 of seamless revenue based financing. Pay a little more on
              days sales are up – and less when it’s slow</p>
            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-[#3c4b5d] lg:max-w-none">
              <div className="relative">
                <dt className="inline font-bold text-[#3c4b5d]">
                  Easy application
                </dt>
                <dd className="inline pl-2">Start your journey to growth with a simple online application. No
                  extensive paperwork, just a straightforward path to funding.</dd>
              </div>
              <div className="relative">
                <dt className="inline font-bold text-[#3c4b5d]">
                  Fast funding
                </dt>
                <dd className="inline pl-2"> Receive up to £100,000 in funding swiftly. Our efficient process
                  ensures quick access to capital, so you can focus on your business.</dd>
              </div>
              <div className="relative">
                <dt className="inline font-bold text-[#3c4b5d]">
                  Effortless payback
                </dt>
                <dd className="inline pl-2"> Repay at your pace with our revenue-based repayment scheme.
                  More flexibility during slower periods means less stress for you.</dd>
              </div>
            </dl>

            <div
              className="wistia_responsive_padding mt-4"
              style={{ padding: '56.25% 0 0 0', position: 'relative' }}
            >
              <div
                className="wistia_responsive_wrapper"
                style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' }}
              >
                <div
                  className="wistia_embed wistia_async_trinfs7mhx seo=true videoFoam=true"
                  style={{ height: '100%', position: 'relative', width: '100%' }}
                >
                  <div
                    className="wistia_swatch"
                    style={{
                      height: '100%',
                      left: '0',
                      opacity: '0',
                      overflow: 'hidden',
                      position: 'absolute',
                      top: '0',
                      transition: 'opacity 200ms',
                      width: '100%',
                    }}
                  >
                    <img
                      src="https://fast.wistia.com/embed/medias/trinfs7mhx/swatch"
                      style={{ filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%' }}
                      alt=""
                      aria-hidden="true"
                      onLoad={handleImageLoad}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className="my-8 text-sm px-1">Financing products are provided by SAPI Group Limited, whose registered address is at
            Senna Building, Gorsuch Place, London, E2 8JF, UK (Companies House Number:
            12014174; Information Commissioner's Office Registration: ZA927191). This is a form of
            receivables finance and not a loan. SAPI is not authorised or regulated by the Financial
            Conduct Authority, and the Financial Ombudsman Service will not be able to consider
            complaints about us.
          </p>

          <>
            <button
              onClick={() => setShowMerchant(!showMerchant)}
              className="min-h-12 border py-2 w-full px-6 bg-[#000050] text-white rounded-lg mb-8">
              {showMerchant ? 'Hide Demo Merchant Details' : 'Show Demo Merchant Details'}
            </button>
            {showMerchant && <div className="grid cols-span-12 rounded-md border shadow-sm bg-white" style={{ overflow: "auto" }}>
              <div className="">
                <img className="w-full h-auto min-h-[175px]" src={MapImg} alt="" />
              </div>
              <div className="text-center py-6 px-[2rem] text-[#3c4b5d]">
                <p className="text-[1.5rem] font-semibold">Richards Green Express Grocery</p>
                <p className="mt-4">Richard Green</p>
                <p className="mt-2">87 Newington Green Rd, London N1 4QX</p>
                <p className="mt-2">Requested: £50,000</p>
                <div className="mb-10 rounded-full flex mt-6 font-bold text-sm items-center justify-center bg-[#dbeafe] w-fit gap-3 mx-auto px-4 py-[6px]">
                  <div className="w-[4px] h-[5px] rounded-full bg-[#3b82f6]"></div>
                  <p className="text-[#3b82f6]">In Progress</p>
                </div>
                <button className="min-h-[53px] border py-2 w-full px-6 bg-[#000050] text-white rounded-lg">Need Help?</button>
              </div>
            </div>}
          </>
        </div> */}

        <div className="sm:w flex flex-col items-center gap-3 sm:mt-0 mt-8">
          {step <= 1 &&
            <>
              <EligibilityCheckCom
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                clickArrow={clickArrow}
                setShowAlert={setShowAlert}
                setShowOtherCards={setShowOtherCards}
                setStep={setStep}
              />
              {showAlert &&
                <>
                  <div className="h-[2px]" id="toast-check"></div>
                  <div className="rounded-md bg-green-50 w-full p-4 check-toast">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                        </svg>
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-green-800">Congratulations, Your Merchant is Eligible</h3>
                        <div className="mt-2 text-sm text-green-700">
                          <p>Your Merchant can access up to £100,000 in funding. Start their
application now and fuel their growth today!</p>
                        </div>
                        {/* <div className="mt-4">
                    <div className="-mx-2 -my-1.5 flex">
                      <button type="button" className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50">View status</button>
                      <button onClick={() => setShowAlert(false)} type="button" className="ml-3 rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50">Dismiss</button>
                    </div>
                  </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="h-[2px]" id="toast-check"></div>
                </>
              }
            </>
          }

          {showOtherCards &&
            <>
              {/* <TellUsCom setStep={setStep} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} clickArrow={clickArrow} /> */}
              {step === 1 && <>
                <BusinessCom setStep={setStep} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} clickArrow={clickArrow} />
                <TellUsAboutCom setStep={setStep} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} clickArrow={clickArrow} />
                <MuchMoneyCom setStep={setStep} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} clickArrow={clickArrow} />
                {/* <BankAccountCom setStep={setStep} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} clickArrow={clickArrow} /> */}
                <YourFundOnWayCom setStep={setStep} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} clickArrow={clickArrow} />
                <div className="text-center py-1">
                  {/* <button
                    onClick={() => {
                      setStep(2);
                      setTimeout(() => {
                        const comId = `menu-4`
                        const element = document.getElementById(comId)
                        if (!element) return
                        element.scrollIntoView({ behavior: 'smooth', block: "start" });
                      }, 500)
                    }}
                    className="bg-[#000050] text-white px-8 py-2"
                  >
                    Reveal Stage 2 Demo
                  </button> */}
                </div>
              </>}

              {step === 2 && <>
                <NewsCom setStep={setStep} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} clickArrow={clickArrow} />
                <IdCheckCom setStep={setStep} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} clickArrow={clickArrow} />
                <div className="text-center py-1">
                  <button
                    onClick={() => {
                      setStep(3);
                      setTimeout(() => {
                        const comId = `menu-6`
                        const element = document.getElementById(comId)
                        if (!element) return
                        element.scrollIntoView({ behavior: 'smooth', block: "start" });
                      }, 500)
                    }}
                    className="bg-[#000050] text-white px-8 py-2"
                  >
                    Reveal Stage 3 Demo
                  </button>
                </div>
              </>}

              {step === 3 && <>
                <SignContractCom setStep={setStep} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} clickArrow={clickArrow} />
                <RepaymentCom setStep={setStep} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} clickArrow={clickArrow} />
                <MoneyOnWayCom setStep={setStep} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} clickArrow={clickArrow} />
                
                <div className="text-center py-1">
                  <button
                    onClick={() => {
                      window.location.reload();
                    }}
                    className="bg-[#000050] text-white px-8 py-2"
                  >
                    Reset Demo
                  </button>
                </div>
              </>}

            </>
          }

          <p className="mt-8 text-xs text-[#3c4b5d]">Financing products are provided by SAPI Group Limited, whose registered address is at
            Senna Building, Gorsuch Place, London, E2 8JF, UK (Companies House Number:
            12014174; Information Commissioner's Office Registration: ZA927191). This is a form of
            receivables finance and not a loan. SAPI is not authorised or regulated by the Financial
            Conduct Authority, and the Financial Ombudsman Service will not be able to consider
            complaints about us.
          </p>
        </div>
      </div>

    </div >
  )
}

export default Overview;