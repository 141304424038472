import { CaretDown } from "@phosphor-icons/react"
import Collapse from "@kunukn/react-collapse";

import BusinessdetailsImg from '../../../assets/img/businessdetails.png'
import { useState } from "react";
import TextInput from "../TextInput";
import SelectInput from "../SelectInput";
import { dBusinessTradingAddrOptions, dBusinessTradingNameOptions } from "./config";
import TextareaInput from "../TextareaInput";
import Text from "../Text";

const TellUsAboutCom = ({ isCollapsed, setIsCollapsed, clickArrow }) => {
  const id = 1, badge = 0;
  const title = `Tell Us About Your Business`
  const subText = `Every business is unique. Tell us more
  about their company's journey and industry.
  With this information, we can tailor our
  solutions to match their specific needs and
  help them thrive.
  `
  const [formData, setFormData] = useState({
    companyName: '',
    companyNumber: '',
    officieAddr: '',
    tradingName: '',
    dtradingName: '',
    tradingAddr: '',
    dtradingAddr: '',
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.dtradingName = formData.dtradingName ? '' : 'Different business trading name is required.';
    if (formData.dtradingName === dBusinessTradingNameOptions[0])
      tempErrors.tradingName = formData.tradingName ? '' : 'Business trading name is required.';
    tempErrors.dtradingAddr = formData.dtradingAddr ? '' : 'Different business trading address is required.';
    if (formData.dtradingAddr === dBusinessTradingAddrOptions[0])
      tempErrors.tradingAddr = formData.tradingAddr ? '' : 'Business trading address is required.';

    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      // Submit form logic here
      console.log('Form is valid, submitting...');
      setIsCollapsed(null);
      const comId = `menu-${id + 1}`
      const element = document.getElementById(comId)
      if (!element) return
      element.scrollIntoView({ behavior: 'smooth', block: "end" });
    } else {
      console.log('Form is invalid, not submitting.');
    }
  };

  return (
    <div id={`menu-${id}`} className="grid cols-span-12 rounded-md border shadow-sm bg-white w-full" style={{ overflow: "auto" }}>
      {(isCollapsed !== id) &&
        <div className="md:pl-12 p-6 md:flex gap-12 text-black">
          <div className="justify-end flex md:hidden -mb-4">
            <div className={"transition-all duration-300 hover:cursor-pointer " + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
              <CaretDown size={20} />
            </div>
          </div>
          <div className="flex justify-center md:mb-0 mb-6">
            <img src={BusinessdetailsImg} className={`w-40 h-40 aspect-square rounded-full`} alt="" />
          </div>
          <div className="md:w-[calc(100%-208px)] text-center md:text-left">
            <div className="md:flex justify-between gap-6 items-center">
              <h2 className="text-[1.7rem] font-[500]">{title}</h2>
              <div className="flex justify-center gap-6 items-center h-20">
                <div className={`rounded-full flex font-bold text-xs items-center justify-center w-fit gap-3 px-4 py-1 h-fit ${badge === 1 ? 'bg-[#dcfce7]' : 'bg-[#dbeafe]'}`}>
                  <div className={`w-[4px] h-[4px] rounded-full ${badge === 1 ? 'bg-[#458046]' : 'bg-[#6085e2]'}`}></div>
                  <p className={`${badge === 1 ? 'text-[#458046]' : 'text-[#6085e2]'}`}>{badge === 1 ? 'Completed' : 'Pending'}</p>
                </div>
                <div className={"transition-all duration-300 hover:cursor-pointer md:block hidden " + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
                  <CaretDown size={20} />
                </div>
              </div>
            </div>
            <p className="">
              {subText}
            </p>
            <div className="text-center md:text-right mt-8 mb-4">
              <button onClick={() => setIsCollapsed(id)} className="bg-[#000050] text-white px-8 py-2">
                Get Started
              </button>
            </div>
          </div>
        </div>
      }
      <Collapse
        style={{ overflow: "auto" }}
        isOpen={isCollapsed === id}
        className="transition-[height] duration-500 ease-in-out gap-1 items-center xl:justify-between text-[#2E3A48]"
      >
        <div className="p-4 md:flex gap-12 text-black">
          <div className="justify-end flex md:hidden -mb-4">
            <div className={"transition-all duration-300 hover:cursor-pointer" + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
              <CaretDown size={20} />
            </div>
          </div>
          <div className="flex justify-center md:mb-0 mb-6">
            <img src={BusinessdetailsImg} className={`w-48 h-48 aspect-square rounded-full`} alt="" />
          </div>
          <div className="md:w-[calc(100%-240px)] text-center md:text-left">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="md:flex justify-between gap-6 items-center">
                <h2 className="text-[1.6rem] font-[500]">{title}</h2>
                <div className="flex justify-center gap-6 items-center h-20">
                  <div className={`rounded-full flex font-bold text-xs items-center justify-center w-fit gap-3 px-4 py-1 h-fit ${badge === 1 ? 'bg-[#dcfce7]' : 'bg-[#dbeafe]'}`}>
                    <div className={`w-[4px] h-[4px] rounded-full ${badge === 1 ? 'bg-[#458046]' : 'bg-[#6085e2]'}`}></div>
                    <p className={`${badge === 1 ? 'text-[#458046]' : 'text-[#6085e2]'}`}>{badge === 1 ? 'Completed' : 'Pending'}</p>
                  </div>
                  <div className={"transition-all duration-300 hover:cursor-pointer hidden md:block " + (isCollapsed === id ? 'rotate-180' : "rotate-0")} onClick={() => clickArrow(id)}>
                    <CaretDown size={20} />
                  </div>
                </div>
              </div>
              <p className="">
                {subText}
              </p>
              <div className="mt-8">
                <div className="gap-8 md:flex mt-4">
                  <div className="md:w-1/2 w-full">
                    <Text
                      label='Company Name'
                      value={'SAPI GROUP LIMITED'}
                    />
                  </div>
                  <div className="md:w-1/2 w-full">
                    <Text
                      label='Company Number'
                      value={'12014174'}
                    />
                  </div>
                </div>

                <div className="gap-8 md:flex mt-4">
                  <Text
                    label='Registered Office Address'
                    value={'Senna Building, Gorsuch Place, London, England, E2 8JF'}
                  />
                </div>

                <div className="gap-8 md:flex mt-4">
                  <SelectInput
                    label='Different Business Trading Name?'
                    value={formData.dtradingName}
                    onChange={(value) => { setFormData({ ...formData, dtradingName: value }) }}
                    placeholder={"Do you have a different trading name?"}
                    error={errors.dtradingName}
                    options={dBusinessTradingNameOptions}
                  />
                </div>
                {formData.dtradingName === dBusinessTradingNameOptions[0]
                  && <div className="gap-8 md:flex mt-4">
                    <TextInput
                      label='Business Trading Name'
                      value={formData.tradingName}
                      onChange={(value) => { setFormData({ ...formData, tradingName: value }) }}
                      placeholder={"Senna Building, Gorsuch Place, London, England, E2 8JF"}
                      error={errors.tradingName}
                    />
                  </div>}

                <div className="gap-8 md:flex mt-4">
                  <SelectInput
                    label='Different Business Trading Address?'
                    value={formData.dtradingAddr}
                    onChange={(value) => { setFormData({ ...formData, dtradingAddr: value }) }}
                    placeholder={"Do you have a different trading address?"}
                    error={errors.dtradingAddr}
                    options={dBusinessTradingAddrOptions}
                  />
                </div>
                {formData.dtradingAddr === dBusinessTradingAddrOptions[0]
                  && <div className="gap-8 md:flex mt-4">
                    <TextareaInput
                      label='Business Trading Address'
                      value={formData.tradingAddr}
                      onChange={(value) => { setFormData({ ...formData, tradingAddr: value }) }}
                      placeholder={"Business trading address"}
                      error={errors.tradingAddr}
                    />
                  </div>}
              </div>
              <div className="text-center md:text-right !mt-14 !mb-6">
                <button
                  type="submit"
                  className="bg-[#000050] text-white px-8 py-2" >
                  Submit
                </button>
              </div>
            </form>


          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default TellUsAboutCom;