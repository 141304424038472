const SelectInput = ({ value, onChange, error, label, placeholder, isRequired, options = [] }) => {
  return (
    <div className="w-full relative mt-8 md:mt-4">
      <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 text-left">{label}</label>
      <select
        className={`${error ? 'border-red-500' : 'border-gray-300'} w-full border bg-gray-50 outline-[#000080] text-gray-900 text-sm rounded-lg focus:ring-[#000050] focus:border-[#000050] block w-full p-2.5`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">{placeholder}</option>
        {options.map((val, ind) => (
          <option value={val} key={ind}>{val}</option>
        ))}
      </select>
      {error && <p className="text-red-500 text-[13px] mt-1 text-left">{error}</p>}
    </div>
  )
}

export default SelectInput;