const Text = ({ value, label }) => {
  return (
    <div className="w-full relative mt-8 md:mt-4">
      <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 nd:text-left">{label}</label>
      <div
        className={`w-full bg-transparent text-gray-900 text-sm block w-full`}
      >
        {value}
      </div>
    </div>
  )
}

export default Text;