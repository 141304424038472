import { DotsThree } from "@phosphor-icons/react";
import Item from './Item';
import SapiImg from '../../assets/img/sapi-white.png'

const Section = ({ name, items, expanded, step, setStep }) => {
    return (
        <div className="">
            <div className='pl-6 py-2'>
                {
                    expanded ? (
                        <p className='text-sm font-bold text-[#7676a1] text-nowrap'>{name}</p>
                    ) : (
                        <DotsThree color='white' weight="bold" size={20} />
                    )
                }
            </div>
            {
                items.filter((val) => val.step <= step).map((element, idx) => (
                    <Item state={element.step < step || element.status} key={idx} Icon={element.icon} expanded={expanded} {...element} />
                ))
            }

            <div className={`${expanded ? 'w-[18.75rem] ' : 'w-[68px]'} 3xl:h-auto 3xl:mt-20 md:h-48 h-36 mt-6 text-white flex flex-col items-center relative 3xl:absolute 3xl:bottom-6 border-red-500`}>
                {expanded &&
                    <>
                        <button className="md:mb-24 mb-12 bg-white btn-primary text-[#000050] py-2 px-4 rounded font-[500] text-sm">Customer Support</button>
                        <p>Powered By</p>
                    </>}
                <img src={SapiImg} className={`${expanded ? 'h-5' : 'h-4'} w-auto`} alt="" />
            </div>
        </div>
    )
}

export default Section