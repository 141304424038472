import { useEffect, useRef, useState } from "react";

const CustomSelect = ({ value = [], onChange, error, label, placeholder, options = [] }) => {
  const [showSelect, setShowSelect] = useState(false)
  const divRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowSelect(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [divRef]);

  return (
    <div className="w-full relative mt-8 md:mt-4" ref={divRef}>
      <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 text-left">{label}</label>
      <button
        type="button"
        onClick={() => setShowSelect(!showSelect)}
        className={`${error ? 'border-red-500' : 'border-gray-300'} flex justify-between w-full border bg-gray-50 text-gray-900 text-sm rounded-lg focus:border-[#000050] block w-full p-2.5`}>
        {value[0] || placeholder}
        <i className="fa fa-sort-desc" aria-hidden="true"></i>
      </button>
      {showSelect &&
        <div className="absolute left-0 border bg-white w-full border-[#000050] z-10">
          <div
            onClick={() => {
              setShowSelect(false)
              onChange('')
            }}
            className="text-sm px-4 py-[2px] hover:text-white hover:bg-blue-500 cursor-default">
            {placeholder}
          </div>
          {options.map((val, ind) => (
            <div
              onClick={() => {
                setShowSelect(false)
                onChange(val.value)
              }}
              key={ind}
              dangerouslySetInnerHTML={{ __html: val.view }} className="text-sm px-4 py-[2px] hover:text-white hover:bg-blue-500 cursor-default"></div>
          ))}
        </div>}
      {error && <p className="text-red-500 text-[13px] mt-1 text-left">{error}</p>}
    </div>
  )
}

export default CustomSelect;